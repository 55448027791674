import React, {Component, Fragment} from 'react'
import {Field} from 'redux-form'
import TextField from '@material-ui/core/TextField'
import {Trans} from 'react-i18next'
import {withStyles} from '@material-ui/core/styles'
import classNames from 'classnames'
import debounce from 'lodash/debounce'
import get from 'lodash/get'
import PropTypes from 'prop-types'
import {default as MaterialInput} from '@material-ui/core/Input'
import Select from '@material-ui/core/Select'
import InputLabel from '@material-ui/core/InputLabel'
import Button from '@material-ui/core/Button'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import FieldLeftLabel from './FieldLeftLabel'
import FormHelperText from '@material-ui/core/FormHelperText'
import { DateTimePicker } from "material-ui-pickers"

export const FanTextField = withStyles(
  theme => ({
    input: {
      '& :disabled': {
        color: theme.palette.text.primary,
      },
    },
    required: {
      color: theme.palette.error.main,
    },
  }),
  {withTheme: true}
)(
  ({
    input,
    label,
    type,
    meta: {touched, error},
    classes,
    className,
    helperText,
    disabled,
    InputProps,
    InputLabelProps,
    ...props
  }) => {
    let SelectProps = {
      native: true,
    }
    if (disabled) {
      SelectProps = {
        ...SelectProps,
        IconComponent: () => null,
      }
    }
    const isRequired = error && get(error, 'props.children') === 'Required'
    if (isRequired && label != null) {
      label = <Fragment>{label} <span className={classes.required}>*</span></Fragment>
    }
    return <TextField
      className={classNames(classes.input, className)}
      label={label}
      error={touched && error != null}
      helperText={touched && error && !isRequired ? error : helperText}
      margin="normal"
      select={type === 'select'}
      type={type}
      SelectProps={SelectProps}
      InputProps={{
        ...InputProps,
        disableUnderline: disabled || (InputProps && InputProps.disableUnderline),
      }}
      InputLabelProps={{
        ...InputLabelProps,
        ...(type === 'date' || type === 'time' || type === 'datetime' ? {shrink: true} : {}),
      }}
      disabled={disabled}
      {...input}
      {...props}
    />
  }
)

export const HiddenInput = ({input, meta: {touched, error}}) =>
  <Fragment>
    <input type="hidden" {...input}/>
    { touched && error && <FormHelperText error>{error}</FormHelperText> }
  </Fragment>

export const FormSelector = withStyles(
  theme => ({
    input: {
      fontSize: 14,
      color: theme.palette.primary.main,
    },
    error: {
      color: theme.palette.error.main,
    },
    disabled: {
      color: theme.palette.text.primary,
    },
  }),
  {withTheme: true}
) (
  ({classes, meta, ...props}) =>
    <FanTextField
      type="select"
      margin="none"
      InputProps={{
        disableUnderline: true,
        classes: {
          root: classNames(classes.input, {[classes.error]: meta.touched && meta.error != null}),
          disabled: classes.disabled,
        },
      }}
      SelectProps={{
        native: false,
        IconComponent: () => null,
        displayEmpty: true,
      }}
      meta={meta}
      {...props}
    />
)

export const FanCheckbox = ({
  input: {value, onChange, onFocus, onBlur}, label, disabled, className, meta, ...props
}) => {
  const control =
    <Checkbox
      checked={value}
      onChange={onChange}
      onFocus={onFocus}
      onBlur={onBlur}
      disabled={disabled}
      className={label == null ? className : null}
      {...props}
    />
  return (
    <Fragment>
      { label == null
        ? control
        : <FormControlLabel
          control={control}
          label={label}
          disabled={disabled}
          className={className}
        />
      }
      { meta && meta.error && <FormHelperText error>{meta.error}</FormHelperText> }
    </Fragment>
  )
}

export const FanRadio = ({
  input: {value, onChange}, values, ...props
}) =>
  <RadioGroup
    value={value}
    onChange={onChange}
    {...props}
  >
    { values.map(({value: itemValue, label, disabled, hidden, classes}) =>
      (!hidden || value === itemValue) &&
        <FormControlLabel key={itemValue}
          value={itemValue}
          control={<Radio color="secondary" classes={classes} />}
          label={label}
          disabled={disabled || hidden}
        />
    )}
  </RadioGroup>

const intFieldParse = val => {
  if (val == null || val === '') {
    return null
  }
  if (val === '-') {
    return val
  }
  if (val.endsWith('.') || val.endsWith('0')) {
    return val
  }
  const parsed = parseFloat(val)
  if (isNaN(parsed)) {
    return null
  }
  return parsed
}

export const IntField = props =>
  <Field {...props} parse={intFieldParse} />

const datetimeFieldParse = val => val == null || val === '' ? null : new Date(val)

export const DatetimeField = props =>
  <Field {...props} parse={datetimeFieldParse} type="datetime-local" />

export class DebouncedInput extends Component {
  static propTypes = {
    input: PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }).isRequired,
    component: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props)
    this.state = {value: props.input.value}
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    this.debouncedOnChange.flush()
    this.setState({value: newProps.input.value})
  }

  handleChange = event => {
    const value = event.target.value
    this.setState({value: value})
    this.debouncedOnChange(value)
  }

  handleKeyPress = event => {
    if (event.key === 'Enter') {
      this.debouncedOnChange.flush()
    }
  }

  debouncedOnChange = debounce(value => {
    this.props.input.onChange(value)
  }, 200)

  getValue() {
    return this.state.value
  }

  render() {
    const {component: Component, ...props} = this.props
    return (
      <Component
        {...props}
        value={this.getValue()}
        onChange={this.handleChange}
        onKeyPress={this.handleKeyPress}
      />
    )
  }
}

export const FormInput = props => <DebouncedInput component={FanTextField} {...props} />

export const FormMaterialInput = props => <DebouncedInput component={MaterialInput} {...props} />

export const LeftLabelSelect = ({meta, label, ...props}) =>
  <FieldLeftLabel meta={meta} label={label}>
    <FormSelector meta={meta} {...props}/>
  </FieldLeftLabel>

export const MaterialInputLabel = withStyles(
  theme => ({
    label: {
      color: theme.palette.text.primary,
      fontSize: 14,
      fontWeight: 500,
    },
  }),
  {withTheme: true}
)(({classes, className, ...props}) =>
  <InputLabel className={classNames(classes.label, className)} {...props}/>)

export const LinkButton = withStyles(
  theme => ({
    linkButton: {
      textTransform: 'none',
      padding: 0,
      '&:hover': {
        background: 'none',
        textDecoration: 'underline',
      },
      minWidth: 'auto',
      fontWeight: 'normal',
    },
    padded: {
      paddingLeft: theme.spacing.unit,
      paddingRight: theme.spacing.unit,
    },
    error: {
      color: theme.palette.error.main,
    },
  }),
  {withTheme: true}
)(({classes, className, error, padded, ...props}) =>
  <Button color="primary" className={
    classNames(classes.linkButton, {
      [classes.error]: error,
      [classes.padded]: padded,
    }, className)} {...props}/>)

export const LeftLabelInputPure = withStyles(theme => ({
  root: {
    display: 'flex',
    alignItems: 'baseline',
  },
  label: {
    margin: 0,
  },
  field: {
    '& :enabled': {
      color: '#555 !important',
    },
    fontSize: 14,
  },
  required: {
    color: theme.palette.error.main,
  },
}), {withTheme: true})(
  ({input, label, meta = {}, labelClassName, classes, children, className, disabled, ...props}) => {
    const {touched, error} = meta
    const isRequired = error && get(error, 'props.children') === 'Required'
    if (isRequired) {
      label = <Fragment>{label} <span className={classes.required}>*</span></Fragment>
    }

    const inputId = props.id || input.id || `id-${input.name}`
    const inputNode = <MaterialInput className={classes.field} {...input}
      id={inputId}
      {...props}
      error={touched && error != null}
      disabled={disabled}
    />
    return (
      <MaterialInputLabel className={classNames(classes.root, className)}>
        <span className={labelClassName}>{label}</span>
        {children == null ? inputNode : <Select input={inputNode}>{children}</Select>}
      </MaterialInputLabel>
    )
  }
)

export const LeftLabelInput = props => <DebouncedInput component={LeftLabelInputPure} {...props} />

export const FormTimeInput = props =>
  <FormInput type="time" InputLabelProps={{shrink: true}} {...props} />

export const FormDateInput = props =>
  <FormInput type="date" InputLabelProps={{shrink: true}} {...props} />

export class FormDateTimeInput extends Component {

  render() {
    const { input: { value, onChange } } = this.props
    return (
      <DateTimePicker ampm={false}
        value={value}
        onChange={param => onChange(param ? param.toDate() : param)}
        InputLabelProps={{shrink: true}} {...this.props}
        okLabel=<Trans>OK</Trans> cancelLabel=<Trans>Cancel</Trans> clearLabel=<Trans>Clear</Trans>
      />
    )
  }
}

export const ReadonlyOverlay = withStyles({
  readonlyOverlay: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    zIndex: 1,
  },
})(
  ({classes}) =>
    <div className={classes.readonlyOverlay}/>
)
