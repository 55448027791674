import thunk from 'redux-thunk'
import {applyMiddleware, createStore} from 'redux'
import {routerMiddleware} from 'react-router-redux'
import {composeWithDevTools} from 'redux-devtools-extension'
import {createLogger} from 'redux-logger'

import rootReducer from '../ducks/reducer'

export function configureStore(initialState, history) {
  const middlewares = [
    thunk,
    routerMiddleware(history),
  ]

  if (process.env && process.env.REACT_APP_LOG_REDUX_ACTIONS === 'true') {
    middlewares.push(createLogger({
      collapsed: true,

      // diff can be usefull, but slows down significantly
      diff: process.env.REACT_APP_LOG_REDUX_ACTIONS_DIFF === 'true',
    }))
  }

  const middleware = applyMiddleware(...middlewares)

  // enhance with dev tools
  return createStore(rootReducer, initialState, composeWithDevTools(middleware))
}
