import React from 'react'
import LoginForm from './LoginForm'
import PropTypes from 'prop-types'
import {push} from 'react-router-redux'
import {connect} from 'react-redux'
import * as actionCreators from 'ducks/auth'
import {isAuthenticated} from 'ducks/auth/selectors'

class SigninView extends React.Component {
  static propTypes = {
    isAuthenticated: PropTypes.bool.isRequired,
    statusText: PropTypes.string,
    authLoginUser: PropTypes.func.isRequired,
    location: PropTypes.shape({
      search: PropTypes.string.isRequired,
    }),
  }

  static defaultProps = {
    statusText: '',
    location: null,
  }

  constructor(props) {
    super(props)

    const redirectRoute = this.props.location
      ? this.extractRedirect(this.props.location.search) || '/'
      : '/'
    this.state = {
      redirectTo: redirectRoute,
    }
  }

  componentDidMount() {
    if (this.props.isAuthenticated) {
      this.props.push('/')
    }
  }

  extractRedirect = (string) => {
    const match = string.match(/next=(.*)/)
    return match ? match[1] : '/'
  }

  login = (value) =>
    this.props.authLoginUser(value.username, value.password, this.state.redirectTo)

  render() {
    return (
      <LoginForm onSubmit={this.login} />
    )
  }
}

const mapStateToProps = (state) => {
  return {
    isAuthenticated: isAuthenticated(state),
    statusText: state.auth.statusText,
  }
}

export default connect(
  mapStateToProps,
  {push, ...actionCreators})(SigninView)
export {SigninView as SigninViewNotConnected}
