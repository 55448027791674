import {Trans} from 'react-i18next'
import getMessageByErrorCode from 'common/utils/errorMessages'

const ALERT_SUCCESS = 'Alert/ALERT_SUCCESS'
const ALERT_ERROR = 'Alert/ALERT_ERROR'
const ALERT_CLEAR = 'Alert/ALERT_CLEAR'

export default (state = {}, action) => {
  const {error, skipGlobalAlert} = action
  if (error != null && !skipGlobalAlert) {
    if (typeof error === 'string' || error.type === Trans) {
      return {
        type: 'error',
        message: error,
      }
    } else if (error.errorCode != null) {
      return {
        type: 'error',
        message: getMessageByErrorCode(error.errorCode),
        details: error.errorMessage,
        stacktrace: error.stacktrace,
      }
    } else if (error.errorMessage != null) {
      return {
        type: 'error',
        message: error.errorMessage,
      }
    }
  }
  switch (action.type) {
    case ALERT_SUCCESS:
      return {
        type: 'success',
        message: action.message,
      }
    case ALERT_ERROR:
      return {
        type: 'error',
        message: action.message,
        details: action.details,
        stacktrace: action.stacktrace,
      }
    case ALERT_CLEAR:
      return {}
    default:
      return state
  }
}

export const alertActions = {
  success,
  error,
  clear,
}

function success(message) {
  return {type: ALERT_SUCCESS, message}
}

function error(message, details, stacktrace) {
  return {
    type: ALERT_ERROR,
    message,
    details,
    stacktrace,
  }
}

function clear() {
  return {type: ALERT_CLEAR}
}
