import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import Backend from 'i18next-xhr-backend'

i18n
  .use(LanguageDetector)
  .use(Backend)
  .init({
    fallbackLng: 'en',

    // have a common namespace used around the full app
    ns: ['translations'],
    defaultNS: 'translations',
    // backend: {
    //     loadPath: '/static/locale/{{lng}}/{{ns}}.json',
    // },

    keySeparator: false, // we use content as keys
    nsSeparator: false, // we use content as keys

    interpolation: {
      escapeValue: false, // not needed for react!!
      formatSeparator: ',',
    },

    react: {
      wait: true,
    },
  })

export default i18n
