import React from 'react'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import {Trans, translate} from 'react-i18next'
import AdditionalInfoPanel from './AdditionalInfo'
import {withStyles} from '@material-ui/core/styles'
import {compose} from 'redux'

const styles = theme => ({
  root: {
    zIndex: theme.zIndex.modal + 1,
  },
})

const AlertDialog = ({classes, isOpen, title, children, onClose, additionalInfo}) => {
  return <Dialog
    className={classes.root}
    open={isOpen}
    onClose={onClose}
    disableBackdropClick
    disableEscapeKeyDown
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
  >
    {title && <DialogTitle id="alert-dialog-title">{title}</DialogTitle>}
    <DialogContent>
      <DialogContentText id="alert-dialog-description">{children}</DialogContentText>
      {additionalInfo && <AdditionalInfoPanel additional={additionalInfo}/>}
    </DialogContent>
    <DialogActions>
      <Button onClick={onClose} color="secondary" autoFocus>
        <Trans>Close</Trans>
      </Button>
    </DialogActions>
  </Dialog>
}

export default compose(
  translate(),
  withStyles(styles, {withTheme: true}),
)(AlertDialog)
