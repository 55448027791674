import React, {Component, Fragment} from 'react'
import './App.scss'
import {connect} from 'react-redux'
import {bindActionCreators} from 'redux'
import {PropTypes} from 'prop-types'
import {translate} from 'react-i18next'
import {alertActions} from 'ducks/alert'
import {infoActions} from 'ducks/info'
import {history} from 'common/utils/history'
import AlertDialog from 'components/AlertDialog'
import Snackbar from '@material-ui/core/Snackbar'
import {compose} from 'redux'

class App extends Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
    info: PropTypes.object.isRequired,
    alert: PropTypes.shape({
      message: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
      details: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
      type: PropTypes.string,
    }).isRequired,
    alertActions: PropTypes.shape({
      clear: PropTypes.func,
    }).isRequired,
  }

  constructor(props) {
    super(props)
    history.listen(() => {
      // clear alert on location change
      this.props.alertActions.clear()
    })
  }

  render() {
    const {alert, alertActions, info, infoActions} = this.props
    const haveAlert = alert.message
    let alertTitle = alert.message
    let alertBody = alert.details
    if (!alertBody) {
      alertTitle = undefined
      alertBody = alert.message
    }
    const additionalInfo = alert.stacktrace
    return (
      <Fragment>
        { haveAlert &&
          <AlertDialog
            color={alert.type}
            isOpen={alert.message != null}
            onClose={alertActions.clear}
            title={alertTitle}
            additionalInfo={additionalInfo}
          >
            {alertBody}
          </AlertDialog>
        }
        <Snackbar
          open={info.message != null}
          autoHideDuration={5000}
          onClose={infoActions.hideInfo}
          key={info.key}
          message={info.message}
        />
        {this.props.children}
      </Fragment>
    )
  }
}

const mapStateToProps = state => {
  return {
    alert: state.alert,
    info: state.info,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    alertActions: bindActionCreators(alertActions, dispatch),
    infoActions: bindActionCreators(infoActions, dispatch),
  }
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  translate(),
)(App)
