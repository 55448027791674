import {api, joinURL, handleError} from 'common/utils'
import {periodicRefresh} from 'common/utils/redux'
import {REFRESH_INTERVAL} from 'common/app_config'

const LOAD_BALANCES = 'partnerWallet/LOAD_BALANCES'
const LOAD_BALANCES_SUCCESS = 'partnerWallet/LOAD_BALANCES_SUCCESS'

const BALANCES_REFRESH_START = 'partnerWallet/BALANCES_REFRESH_START'
const BALANCES_REFRESH_STOP = 'partnerWallet/BALANCES_REFRESH_STOP'

const initialState = {
  balances: null,
  isAutoRefresh: 0,
}

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case LOAD_BALANCES_SUCCESS:
      return {
        ...state,
        balances: action.payload.balances.balances,
      }

    case BALANCES_REFRESH_START:
    case BALANCES_REFRESH_STOP:
      return {
        ...state,
        isAutoRefresh: state.isAutoRefresh + 1,
      }

    default:
      return state
  }
}

export const loadBalances = ({blockUi} = {blockUi: false}) =>
  dispatch => {
    const meta = {isBlocking: blockUi}
    dispatch({
      type: LOAD_BALANCES,
      meta,
    })
    const url = joinURL(api.baseUrl, "/accounts/balances")
    return api.read({url: url})
      .then(balances => {
        dispatch({
          type: LOAD_BALANCES_SUCCESS,
          payload: {balances},
          meta,
        })
      })
      .catch(error => {
        dispatch({
          type: '_FAIL',
          meta,
        })
        throw error
      })
      .catch(handleError(dispatch))
  }

export function startBalancesAutoRefresh() {
  return (dispatch, getState) => {
    dispatch({type: BALANCES_REFRESH_START})
    dispatch(loadBalances())

    const initialAutoRefresh = getState().partnerWallet.isAutoRefresh
    return dispatch(periodicRefresh({
      actionCreator: () => dispatch => {
        return dispatch(loadBalances())
      },
      isAutoRefresh: state => state.partnerWallet.isAutoRefresh === initialAutoRefresh,
      refreshInterval: REFRESH_INTERVAL,
    }))
  }
}

export function stopBalancesAutoRefresh() {
  return {type: BALANCES_REFRESH_STOP}
}

export function dumpVersion() {
  console.log(`Version: ${process.env.REACT_APP_CIRCLE_SHA1}`)
}
