import React from 'react'

export const LeftLabelContext = React.createContext({labelClassName: null})

const FormSection = ({labelClassName, children}) =>
  <LeftLabelContext.Provider value={{labelClassName}}>
    {children}
  </LeftLabelContext.Provider>

export default FormSection
