import React from 'react'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import {withStyles} from '@material-ui/core/styles'

export const ButtonWithLoader = withStyles(
  () => ({
    wrapper: {
      position: 'relative',
      display: 'inline-flex',
    },
  }), {withTheme: true}
)(({loading, disabled, onClick, children, buttonComponent: Component, classes, className, ...props}) => {
  const loaderSize = 20
  Component = Component || Button
  return (
    <div className={classes.wrapper}>
      {/* visibility is used for hiding to save button dimensions */}
      <Component onClick={onClick} color="secondary"
        className={className}
        disabled={loading || disabled}
        {...props}
        style={{visibility: loading ? 'hidden' : 'visible'}}
      >
        {children}
      </Component>
      { loading &&
        <CircularProgress size={loaderSize}
          style={{
            // position absolute wil work by itself in all recent browsers
            // because of flex and 'align-items: center' on parent,
            // but IE11...
            position: 'absolute',
            left: `calc(50% - ${loaderSize / 2}px)`,
            top: `calc(50% - ${loaderSize / 2}px)`,
          }}
        />
      }
    </div>
  )
})

export class WaitForPromiseButton extends React.PureComponent {
  state = {
    clickInProgress: false,
  }

  render() {
    const {loading, ...props} = this.props
    const {clickInProgress} = this.state
    return <ButtonWithLoader {...props} onClick={this.handleClick} loading={loading || clickInProgress}/>
  }

  handleClick = event => {
    const {onClick} = this.props
    this.setState({clickInProgress: true})
    Promise.resolve(onClick(event)).then(() => {
      if (!this.isUnmounted) {
        this.setState({clickInProgress: false})
      }
    })
  }

  componentWillUnmount() {
    this.isUnmounted = true
  }
}
