import React from 'react'
import {Provider} from 'react-redux'
import {ConnectedRouter} from 'react-router-redux'
import {Route, Switch} from 'react-router'
import SigninView from 'Signin'
import Callback from 'Callback'
import PropTypes from 'prop-types'
import App from './App'
import {connect} from 'react-redux'
import {bindActions} from 'common/utils'
import {I18nextProvider} from 'react-i18next'
import 'common/utils/polyfills'
import {loginRequired} from 'common/utils'
import i18n from './i18n'
import ThemeProvider from './ThemeProvider'
import asyncComponent from 'components/AsyncComponent'
import CssBaseline from '@material-ui/core/CssBaseline'
import {MuiPickersUtilsProvider} from 'material-ui-pickers';
import MomentUtils from '@date-io/moment';
import {changeMomentLocale} from 'ducks/locale'
import {isAuthenticated} from 'ducks/auth/selectors'
import {scheduleRenewal} from 'ducks/auth'

const NavPanelProtected = loginRequired(
  asyncComponent(() => import('NavPanel/NavPanel' /* webpackChunkName: 'NavPanel' */)))

class Root extends React.Component {
  static propTypes = {
    store: PropTypes.shape().isRequired,
    history: PropTypes.shape().isRequired,
  }

  render() {
    const {store, history, moment, actions, isAuthenticated} = this.props
    i18n.on('languageChanged', (lang) => {actions.changeMomentLocale(lang)})
    if (isAuthenticated) actions.scheduleRenewal()
    return (
      <I18nextProvider i18n={i18n}>
        <ThemeProvider>
          <Provider store={store}>
            <ConnectedRouter history={history}>
              <MuiPickersUtilsProvider utils={MomentUtils} locale={moment}>
                <App>
                  <CssBaseline/>
                  <Switch>
                    <Route path="/signin" component={SigninView} />
                    <Route path="/callback" component={Callback} />
                    <Route path="/" component={NavPanelProtected} />
                  </Switch>
                </App>
              </MuiPickersUtilsProvider>
            </ConnectedRouter>
          </Provider>
        </ThemeProvider>
      </I18nextProvider>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    isAuthenticated: isAuthenticated(state),
    moment: state.locale.moment,
  }
}

export default connect(mapStateToProps, bindActions({changeMomentLocale, scheduleRenewal}))(Root)
