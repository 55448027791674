import moment from "moment"
import "moment/locale/ru"

const CHANGE_MOMENT_LOCALE = 'Locale/CHANGE_MOMENT_LOCALE'

export default (state = {moment: null}, action) => {
  switch (action.type) {
    case CHANGE_MOMENT_LOCALE:
      return {
        moment: action.payload,
      }
    default:
      return state
  }
}

export function changeMomentLocale(newLocale) {
  return dispatch => {
    dispatch({type: CHANGE_MOMENT_LOCALE, payload: moment.locale(newLocale)})
  }
}
