import * as customerApi from './CustomerService'
import {handleError} from 'common/utils'

const LOAD = 'customer/list/LOAD'
const LOAD_SUCCESS = 'customer/list/LOAD_SUCCESS'
const LOAD_FAIL = 'customer/list/LOAD_FAIL'

const initialState = {
  customers: null,
}

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case LOAD_SUCCESS:
      return {
        ...state,
        customers: action.payload.customers.accountList,
      }

    default:
      return state
  }
}

export const loadCustomers = ({blockUi} = {blockUi: true}) =>
  dispatch => {
    const meta = {isBlocking: blockUi}
    dispatch({
      type: LOAD,
      meta,
    })
    return customerApi.getCustomers()
      .then(customers => {
        dispatch({
          type: LOAD_SUCCESS,
          payload: {customers},
          meta,
        })
      })
      .catch(error => {
        dispatch({
          type: LOAD_FAIL,
          meta,
        })
        throw error
      })
      .catch(handleError())
  }
