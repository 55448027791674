import React from 'react'
import {Trans} from 'react-i18next'

export const required = value => (value != null && value !== ''
  ? undefined
  : <Trans>Required</Trans>
)

export const atLeastOne = ({predicate, msg = <Trans>At least one should be selected</Trans>}) =>
  value => {
    let arrayToTest = null
    if (Array.isArray(value)) {
      arrayToTest = value
    } else if (value instanceof Set) {
      arrayToTest = Array.from(value)
    } else {
      return msg
    }
    return arrayToTest.some(predicate) ? undefined : msg
  }

export const arrayNotEmpty = value => Array.isArray(value) && value.length === 0
  && <Trans>Should not be empty</Trans>

const identifierPattern = /^[A-Za-z_]+[A-Za-z0-9_]*$/

export const validIdentifier = value => {
  if (!identifierPattern.test(value)) {
    return <Trans>Valid identifier required</Trans>
  }
}

