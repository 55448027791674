import * as customerApi from './CustomerService'
import {handleError} from 'common/utils'
import {periodicRefresh} from 'common/utils/redux'
import {REFRESH_INTERVAL} from 'common/app_config'

const LOAD_CUSTOMER_BALANCES = 'customer/LOAD_BALANCES'
const LOAD_CUSTOMER_BALANCES_SUCCESS = 'customer/LOAD_BALANCES_SUCCESS'
const LOAD_CUSTOMER_BALANCES_FAIL = 'customer/LOAD_BALANCES_FAIL'

const BALANCES_REFRESH_START = 'customer/BALANCES_REFRESH_START'
const BALANCES_REFRESH_STOP = 'customer/BALANCES_REFRESH_STOP'

const initialState = {
  balances: null,
  isAutoRefresh: 0,
}

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case LOAD_CUSTOMER_BALANCES:
      return {
        ...state,
        balances: null,
      }

    case LOAD_CUSTOMER_BALANCES_SUCCESS:
      return {
        ...state,
        balances: action.payload.balances.balances,
      }

    case BALANCES_REFRESH_START:
    case BALANCES_REFRESH_STOP:
      return {
        ...state,
        isAutoRefresh: state.isAutoRefresh + 1,
      }

    default:
      return state
  }
}

export const loadCustomerBalances = (customerId, {blockUi} = {blockUi: false}) =>
  dispatch => {
    const meta = {isBlocking: blockUi}
    if (blockUi)
      dispatch({
        type: LOAD_CUSTOMER_BALANCES,
        meta,
      })
    return customerApi.loadBalances(customerId)
      .then(balances => {
        dispatch({
          type: LOAD_CUSTOMER_BALANCES_SUCCESS,
          payload: {balances},
          meta,
        })
      })
      .catch(error => {
        dispatch({
          type: LOAD_CUSTOMER_BALANCES_FAIL,
          meta,
        })
        throw error
      })
      .catch(handleError())
  }

export function startCustomerBalancesAutoRefresh(customerId) {
  return (dispatch, getState) => {
    dispatch({type: BALANCES_REFRESH_START})
    dispatch(loadCustomerBalances(customerId))

    const initialAutoRefresh = getState().customer.balances.isAutoRefresh
    return dispatch(periodicRefresh({
      actionCreator: () => dispatch => {
        return dispatch(loadCustomerBalances(customerId))
      },
      isAutoRefresh: state => state.customer.balances.isAutoRefresh === initialAutoRefresh,
      refreshInterval: REFRESH_INTERVAL,
    }))
  }
}

export function stopCustomerBalancesAutoRefresh() {
  return {type: BALANCES_REFRESH_STOP}
}

