import {api, joinURL} from 'common/utils'

export const getCustomers = customerId => {
  let url = joinURL(api.baseUrl, '/accounts')
  if (customerId != null) {
    url = joinURL(url, `/${customerId}`)
  }
  return api.read({url})
}

export const loadBalances = customerId => {
  const url = joinURL(api.baseUrl, `/accounts/${customerId}/balances`)
  return api.read({url: url})
}
