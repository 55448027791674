import * as transferApi from './TransferService'
import {handleError} from 'common/utils'
import {periodicRefresh} from 'common/utils/redux'
import {loadCustomers} from 'ducks/customer/list'
import {REFRESH_INTERVAL} from 'common/app_config'

const LOAD = 'transfer/list/LOAD'
const LOAD_SUCCESS = 'transfer/list/LOAD_SUCCESS'
const LOAD_FAIL = 'transfer/list/LOAD_FAIL'

const TRANSFER_REFRESH_START = 'transfer/list/TRANSFER_REFRESH_START'
const TRANSFER_REFRESH_STOP = 'transfer/list/TRANSFER_REFRESH_STOP'


const initialState = {
  transfers: [],
  hasMoreTransfers: false,
  isAutoRefresh: 0,
  loading: false,
}

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case LOAD:
      return {
        ...state,
        loading: !action.meta.refresh,
      }

    case LOAD_SUCCESS:
      return {
        ...state,
        transfers: action.meta.refresh ? action.payload.transfers.statuses :
            state.transfers.concat(action.payload.transfers.statuses),
        hasMoreTransfers: action.payload.transfers.statuses.length === transferApi.TRANSFER_LOAD_LIMIT,
        loading: false,
      }

    case LOAD_FAIL:
      return {
        ...state,
        hasMoreTransfers: true,
        loading: false,
      }

    case TRANSFER_REFRESH_START:
    case TRANSFER_REFRESH_STOP:
      return {
        ...state,
        transfers: action.meta.clear ? [] : state.transfers,
        isAutoRefresh: state.isAutoRefresh + 1,
      }

    default:
      return state
  }
}

export const loadTransfers = ({customerId, currency = null, datetime = null, refresh=false,
                              blockUi = true, showError = true} = {}) =>
  dispatch => {
    const meta = {isBlocking: blockUi, refresh: refresh}
    dispatch({
      type: LOAD,
      meta,
    })
    return transferApi.getTransfers({customerId, currency, datetime})
      .then(transfers => {
        dispatch({
          type: LOAD_SUCCESS,
          payload: {transfers},
          meta,
        })
      })
      .catch(error => {
        dispatch({
          type: LOAD_FAIL,
          meta,
        })
        if (showError) {
          throw error
        }
      })
      .catch(handleError())
  }

export const makeTransfer = ({accountId, customerId, currency, amount, note}) =>
  () =>
    transferApi.makeTransfer({accountId, customerId, currency, amount, note})

export function startTransferAutoRefresh({customerId, currency, refresh}) {
  return (dispatch, getState) => {
    const meta = {clear: !refresh}
    dispatch({type: TRANSFER_REFRESH_START, meta})
    dispatch(loadCustomers({blockUi: false, showError: false}))
    dispatch(loadTransfers({blockUi: !refresh, customerId, currency, refresh: refresh}))

    const initialAutoRefresh = getState().transfer.list.isAutoRefresh
    return dispatch(periodicRefresh({
      actionCreator: () => dispatch => {
        dispatch(loadCustomers({blockUi: false, showError: false}))
        return dispatch(loadTransfers({customerId, currency, blockUi: false, showError: false,
                                      refresh: true}))
      },
      isAutoRefresh: state => state.transfer.list.isAutoRefresh === initialAutoRefresh,
      refreshInterval: REFRESH_INTERVAL,
    }))
  }
}

export function stopTransferAutoRefresh(clear=true) {
  const meta = {clear: clear}
  return {type: TRANSFER_REFRESH_STOP, meta}
}
