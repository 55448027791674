import {sleep} from '.'

export function periodicRefresh({actionCreator, isAutoRefresh, refreshInterval}) {
  return (dispatch, getState) => {
    sleep(refreshInterval).then(() => {
      if (isAutoRefresh(getState())) {
        const onAny = () => dispatch(periodicRefresh({actionCreator, isAutoRefresh, refreshInterval}))
        dispatch(actionCreator()).then(onAny).catch(onAny)
      }
    })
  }
}
