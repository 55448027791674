import {authHeader} from './authHeader'
import {fakeCallApi} from './fakeBackend'
import {SubmissionError} from 'redux-form'
import {isAuthenticated} from 'ducks/auth/selectors'
import {renewToken} from 'ducks/auth'
import store from 'store'

const HTTP_STATUS_ACCEPTED = 202
const HTTP_STATUS_NO_CONTENT = 204
const HTTP_STATUS_UNAUTHORIZED = 401

export const baseUrl = process.env.REACT_APP_BACKEND_API_URL

function realCallApi({url, method = 'GET', data, headers = {}, acceptPlain}) {
  const callApi_ = async () => {
    let contentTypeHeaders = {}
    let sendData = data
    if (!(data instanceof FormData)) {
      contentTypeHeaders = {'Content-Type': 'application/json'}
      sendData = JSON.stringify(sendData)
    }
    const requestOptions = {
      method: method,
      headers: {
        ...(await authHeader()),
        'Accept': acceptPlain ? 'text/plain' : 'application/json',
        ...contentTypeHeaders,
        ...headers,
      },
    }
    if (sendData) {
      requestOptions.body = sendData
    }
    return fetch(url, requestOptions)
  }

  return callApi_()
    .then(response => handleTokenExpiration({response, onSuccess: callApi_}))
    .then(checkHttpStatus)
    .then(handleResponse({acceptPlain}))
}

const callApi = (process.env && process.env.REACT_APP_FAKE_BACKEND &&
                 process.env.REACT_APP_FAKE_BACKEND === 'true') ? fakeCallApi(realCallApi) : realCallApi

const handleResponse = ({acceptPlain}) =>
  response => {
    if (response.status === HTTP_STATUS_NO_CONTENT || response.status === HTTP_STATUS_ACCEPTED) {
      return null
    }
    return acceptPlain ? response.text() : response.json()
  }

export function checkHttpStatus(response) {
  if (response.status >= 200 && response.status < 300) {
    return response
  }

  return response.json().then(errorObject => {
    const error = new SubmissionError({
      _error: (errorObject.errorCode || errorObject.errorMessage || 'Unexpected Error'),
      ...(errorObject.fields || {}),
    })
    error.httpStatus = response.status
    error.errorCode = errorObject.errorCode
    error.errorMessage = errorObject.errorMessage
    error.stacktrace = errorObject.stacktrace

    console.error(`Error status=${error.httpStatus} code=${error.errorCode} message=${error.errorMessage}`)
    throw error
  }, () => {
    const error = new SubmissionError({_error: response.status})
    error.httpStatus = response.status
    console.error(`Error status=${error.httpStatus}`)
    throw error
  })
}

async function handleTokenExpiration({response, onSuccess}) {
  if (response.status === HTTP_STATUS_UNAUTHORIZED && isAuthenticated(store.getState())) {
    try {
      await store.dispatch(renewToken())
    } catch (err) {
      console.log(err)
      return response
    }
    return onSuccess()
  }
  return response
}

export function create({url, data, headers = {}}) {
  return callApi({url, method: 'POST', data, headers})
}

export function read({url, data, headers = {}, acceptPlain}) {
  return callApi({url, method: 'GET', data, headers, acceptPlain})
}

export function update({url, data, headers = {}}) {
  return callApi({url, method: 'PUT', data, headers})
}

export function del({url, data, headers = {}}) {
  return callApi({url, method: 'DELETE', data, headers})
}

export function patch({url, data, headers = {}}) {
  return callApi({url, method: 'PATCH', data, headers})
}
