import React from 'react'
import {connect} from 'react-redux'
import {push} from 'react-router-redux'
import PropTypes from 'prop-types'
import {isAuthenticated} from 'ducks/auth/selectors'
import {login} from 'ducks/auth'

export default function requireAuthentication(Component) {
  class AuthenticatedComponent extends React.Component {
    static propTypes = {
      isAuthenticated: PropTypes.bool.isRequired,
      location: PropTypes.shape({
        pathname: PropTypes.string.isRequired,
      }).isRequired,
      push: PropTypes.func.isRequired,
    }

    componentDidMount() {
      this.checkAuth()
    }

    componentDidUpdate() {
      this.checkAuth()
    }

    checkAuth() {
      // const redirectAfterLogin = this.props.location.pathname + this.props.location.search
      if (!this.props.isAuthenticated) {
        this.props.login()
      }
    }

    render() {
      const {isAuthenticated, ...rest} = this.props
      return isAuthenticated && <Component {...rest} />
    }
  }

  return connect(state => ({
    isAuthenticated: isAuthenticated(state),
  }), {push, login})(AuthenticatedComponent)
}
