import createMuiTheme from '@material-ui/core/styles/createMuiTheme'
import grey from '@material-ui/core/colors/grey'

const defaultTheme = createMuiTheme({typography: {useNextVariants: true}})

const createTheme = () =>
  createMuiTheme({
    // palette: {
    //   primary: blue,
    //   secondary: {
    //     light: '#56c8d8',
    //     main: '#0097a7',
    //     dark: '#006064',
    //     contrastText: '#fff',
    //   },
    // },
    fan: {
      drawerWidth: 250,
      drawerCollapsedWidth: 80,
      drawerCollapsedWidthSm: 56,
      panelPadding: 24,
    },
    overrides: {
      // MuiTypography: {
      //   h6: {
      //     fontSize: '1.25rem',
      //   },
      //   subtitle1: {
      //     fontSize: '1.25rem',
      //   },
      // },
      // MuiMenuItem: {
      //   root: {
      //     height: 10,
      //     fontSize: 14,
      //   },
      // },
      MuiToolbar: {
        regular: {
          minHeight: 110,
          [defaultTheme.breakpoints.up('sm')]: {
            minHeight: 84,
          },
        },
      },
    },
    palette: {
      primary: {
        light: '#757ce8',
        main: grey[900],
        dark: '#37474F',
        contrastText: '#fff',
      },
      secondary: {
        main: '#d51030',
      },
    },
    status: {
      danger: 'orange',
    },
    typography: {
      fontFamily: '"PT Sans", "Helvetica", "Arial", sans-serif',
      fontSize: 16,
      useNextVariants: true,
    },
  })

const themes = {}

const getTheme = lang => {
  let theme = themes[lang]
  if (theme == null) {
    theme = themes[lang] = createTheme(lang)
  }
  return theme
}

export default getTheme
