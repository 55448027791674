import {api, joinURL} from 'common/utils'

export const TRANSFER_LOAD_LIMIT = 200

export const getTransfers = ({customerId, currency=null, datetime=null}) => {
  const url = joinURL(api.baseUrl,
    `/accounts/transfers${customerId ? `/${customerId}` : ''}?currency=${currency ? currency : ''}\
    &fromTime=${datetime ? datetime : ''}&limit=${TRANSFER_LOAD_LIMIT}&direction=LT`)
  return api.read({url: url})
}

export const makeTransfer = ({accountId, customerId, currency, amount, note}) =>
  api.create({
    url: joinURL(api.baseUrl, '/accounts/transfers'),
    data: {from: accountId, to: customerId, currency: currency, amount: `${amount}`, note: `${note}`},
  })
