import createAuth0Client from '@auth0/auth0-spa-js';
import {api, joinURL} from 'common/utils';

let tokenRenewalTimeout = null;
let client = null;
let expiresAt = 0;

const config = {
  domain: process.env.REACT_APP_AUTH_DOMAIN,
  client_id: process.env.REACT_APP_AUTH_CLIENT_ID,
  redirect_uri: process.env.REACT_APP_AUTH_CALLBACK_URL,
  audience: process.env.REACT_APP_BACKEND_API_AUDIENCE,
  response_type: 'token id_token',
  scope: `openid profile email create:accounts read:accounts update:users
    create:orders get:balances read:transfers create:transfers get:balances`,
}

const getAuth0Client = () => {
  return new Promise(async (resolve, reject) => {
    if (!client)  {
      try {
        client = await createAuth0Client(config);
        resolve(client);
      } catch (e) {
        reject(new Error('getAuth0Client Error', e));
      }
    }
    resolve(client)
  })
}

const getAccessToken = async (...p) => {
  const client = await getAuth0Client()
  return await client.getTokenSilently(...p)
}

const login = async () => {
  const auth0 = await getAuth0Client();
  return await auth0.loginWithRedirect({'prompt': 'login'});
};

const AUTH_ROLES = 'https://exchange.pi/authorization/roles'
const PARTNER_ROLE_PREFIX = 'expi:ptn::'
const BO_ROLE_PREFIX = 'expi:bo::'
const PARTNER_RE = new RegExp(`^(?:${PARTNER_ROLE_PREFIX}|${BO_ROLE_PREFIX})([^:]*).*$`)

const getPartnerId = (user) => {
  const role = user[AUTH_ROLES].find((role) => PARTNER_RE.test(role))
  return role && role.replace(PARTNER_RE, '$1')
}

const handleAuthentication = async (renewFail) => {
  const auth0 = await getAuth0Client();
  const authResult = await auth0.handleRedirectCallback();
  if (authResult) {
    const user = await auth0.getUser();
    const partnerId = getPartnerId(user);
    if (!partnerId) {
      console.error('Not a partner')
      throw new Error('Not a partner')
    } else {
      scheduleRenewal(() => {})
      return {authResult, partnerId};
    }
  } else {
    throw new Error('Could not get auth0 user info');
  }
};

const getAccount = () => {
  const url = joinURL(api.baseUrl, '/accounts')
  return api.read({url: url})
};

const logout = async () => {
  const auth0 = await getAuth0Client();
  auth0.logout({'localOnly': true});
};

const isAuthenticated = async () => {
  const auth0 = await getAuth0Client();
  return await auth0.isAuthenticated();
};

const getProfile = async () => {
  const auth0 = await getAuth0Client();
  return await auth0.getUser();
};

const renewToken = async () => {
  const auth0 = await getAuth0Client();
  return await auth0.getTokenSilently();
};

const scheduleRenewal = (renewFail) => {
  if (tokenRenewalTimeout !== null) return;
  const delay = expiresAt - Date.now()

  if (delay > 0) {
    tokenRenewalTimeout = setTimeout(() => {
      tokenRenewalTimeout = null
      renewToken(renewFail)
    }, delay);
  }
}

const qwe = () => {return "qwe" }

export default {
  qwe,
  getProfile,
  getAccount,
  handleAuthentication,
  isAuthenticated,
  login,
  logout,
  scheduleRenewal,
  renewToken,
  getAccessToken,
}
