export default function reducer(state = 0, action) {
  if (!isBlocking(action.meta)) {
    return state
  }
  if (isActionFinished(action.type)) {
    let nextState = state - 1
    if (nextState < 0) {
      console.warn('Negative busy factor', action)
      nextState = 0
    }
    return nextState
  }
  return state + 1
}

export const isBusy = state => state.busy > 0

const isBlocking = meta =>
  meta != null && meta.isBlocking

const isActionFinished = type =>
  type != null && (type.endsWith('_SUCCESS') || type.endsWith('_FAIL') || type.endsWith('_FAILURE'))
