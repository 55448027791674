import {push} from 'react-router-redux'
import AuthService from './AuthService'

const LOGIN_REQUEST = 'auth/LOGIN_REQUEST'
const LOGIN_SUCCESS = 'auth/LOGIN_SUCCESS'
const LOGIN_FAILURE = 'auth/LOGIN_FAILURE'
const PROFILE_RECEIVED = 'auth/PROFILE_RECEIVED'
export const LOGOUT_SUCCESS = 'auth/LOGOUT_SUCCESS'

const INITIAL_STATE = {
  isAuthenticated: AuthService.isAuthenticated(),
  authError: '',
  profile: null,
  accountId: '',
}

export default (state = INITIAL_STATE, action) => {
  const {type, payload} = action

  switch (type) {
    case LOGIN_REQUEST:
      return {...state, authError: '', isAuthenticated: false}
    case LOGIN_SUCCESS:
      return {...state, isAuthenticated: true, accountId: payload}
    case LOGIN_FAILURE:
      return {
        ...state,
        isAuthenticated: false,
        profile: null,
        authError: payload,
      }
    case LOGOUT_SUCCESS:
      return {
        isAuthenticated: false,
        profile: null,
        authError: '',
        accountId: '',
      }
    case PROFILE_RECEIVED:
      return {
        ...state,
        profile: payload,
      }
    default:
      return state
  }
}

export const login = () => async (dispatch) => {
  dispatch({type: LOGIN_REQUEST})
  await AuthService.login()
}

export const logout = () => async (dispatch) => {
  await AuthService.logout()
  dispatch(push('/'))
  dispatch({type: LOGOUT_SUCCESS})
}

export const handleAuthCallback = () => async (dispatch) => {
  try {
    // eslint-disable-next-line
    const {authResult, partnerId} = await AuthService.handleAuthentication()
    dispatch({type: LOGIN_SUCCESS, payload: partnerId})
    const profile = await AuthService.getProfile()
    dispatch({type: PROFILE_RECEIVED, payload: profile})
    dispatch(push('/'))
  } catch (err) {
    const payload = err.error ? `${err.error}: ${err.errorDescription}` : err
    dispatch({type: LOGIN_FAILURE, payload: payload});
  }
}

export const renewToken = () => async(dispatch) => {
  await AuthService.renewToken(() => {})
}

export const scheduleRenewal = () => (dispatch) => {
  AuthService.scheduleRenewal(() => {})
}
