import React from 'react'
import {bindActionCreators} from 'redux'
import {Trans} from 'react-i18next'
import * as api from './api'
import store from 'store'

export const handleError = (dispatch, skipUnauthorized) =>
  error => {
    dispatch = dispatch || store.dispatch
    if (error) {
      console.error(error)
      if (error.httpStatus === 401) {
        if (skipUnauthorized) {
          throw error
        }
        // alert is dispatched in another place
        return error
      } else if (error.errorCode != null || error.errorMessage != null) {
        dispatch({
          type: null,
          error: error,
        })
        return error
      }
    } else {
      console.error('Unknown error')
    }

    // Most likely connection issues
    dispatch({type: null, error: <Trans>Connection Error</Trans>})
    return error
  }

export const sleep = duration => new Promise(resolve => setTimeout(resolve, duration))

export {api}
export {default as loginRequired} from './requireAuthentication'
export {default as formatCurrency} from './formatCurrency'

export function joinURL(url1, url2) {
  url1 = url1.trim().replace(/\/+$/, '')
  url2 = url2.trim().replace(/^\/+/, '')
  return [url1, url2].join('/')
}

export function dropLastUrlPart(url) {
  return url.replace(/\/[^/]+\/?$/, '')
}

export function dispatchWindowEvent(eventName) {
  let event = null
  if (typeof(Event) === 'function') {
    event = new Event(eventName)
  } else {
    // IE11
    event = window.document.createEvent('Event')
    event.initEvent(eventName, true, true)
  }
  window.dispatchEvent(event)
}

export function executionStatusName(value) {
  switch (value) {
    case 'TERMINATED': return <Trans>Interrupted</Trans>
    case 'SUCCESS': return <Trans>Success</Trans>
    case 'FAILED': return <Trans>Fail</Trans>
    case 'RUNNING': return <Trans>Running</Trans>
    case 'PARTIALLY_FAILED': return <Trans>Success with errors</Trans>
    default: return '-'
  }
}

// to pass binded action creators
// as *actions* prop to connected component
export const bindActions = actionsObj =>
  dispatch => ({
    actions: bindActionCreators(actionsObj, dispatch),
  })

export {default as isNumeric} from './isNumeric'

export const isNullOrEmptyString = s => s == null || s === ''
