const OPEN = 'confirmation/OPEN'
const CLOSE = 'confirmation/CLOSE'

const START = 'confirmation/START'
const STOP = 'confirmation/STOP'

const initialState = {
  confirming: {},
}

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case OPEN:
      return {
        ...state,
        [action.payload.actionKey]: action.payload.obj,
      }

    case CLOSE:
      return {
        ...state,
        [action.payload.actionKey]: null,
      }

    case START:
      return {
        ...state,
        confirming: {
          ...state.confirming,
          [action.payload.actionKey]: true,
        },
      }

    case STOP:
      return {
        ...state,
        confirming: {
          ...state.confirming,
          [action.payload.actionKey]: false,
        },
      }

    default:
      return state
  }
}

export const confirm = (actionKey, obj) => ({
  type: OPEN,
  payload: {
    actionKey,
    obj,
  },
})

export const closeConfirmation = actionKey => ({
  type: CLOSE,
  payload: {actionKey},
})

export const _handleConfirmAction = (actionKey, action) =>
  dispatch => {
    dispatch({
      type: START,
      payload: {actionKey},
    })
    return Promise.resolve(action)
      .then(() => {
        dispatch({
          type: STOP,
          payload: {actionKey},
        })
        dispatch(closeConfirmation(actionKey))
      })
  }

export function _getConfirmationObject(state, actionKey) {
  return state.confirmation[actionKey]
}

export function _isConfirming(state, actionKey) {
  return state.confirmation.confirming[actionKey]
}
