/* eslint-disable */
import { SubmissionError } from 'redux-form'
import { sleep } from '.'

export function fakeCallApi(realCallApi) {
  return ({url, method = 'GET', data, headers = {}}) => {
    return sleep(750).then(() => {
      let matchResult = url.match(/\/transfers(\/(\d+))?/)
      if (matchResult != null) {
        const customerId = matchResult[2]
        switch (method) {
          case 'GET':
            if (customerId == null) {
              return {statuses: transfers}
            }
            const ret = transfers.filter(t => String(t.customer.id) === String(customerId))
            return {statuses: ret}
          case 'POST':
            if (customerId == 2) {
              throw new SubmissionError({_error: 'Some error'})
            }
            return {}
          default:
            // nothing to do
        }
      }
      if (url.endsWith('/accounts')) {
        return {accountList: customers}
      }
      if (url.endsWith('/balances')) {
        return [
          {
            currency: 'BTH',
            amount: 0.00500324,
          },
          {
            currency: 'ETH',
            amount: 0.0005422,
          },
          {
            currency: 'USD',
            amount: 1288.03,
          },
          {
            currency: 'EUR',
            amount: 8234.22,
          },
          {
            currency: 'RUB',
            amount: 10003234.22,
          },
        ]
      }
      return realCallApi({url, method, data, headers})
    })
  }
}

const transfers = [
  {
    created: "2018-03-15T11:44:55.267+0000",
    currency: 'BTC',
    amount: 123.05,
    status: 'REQUESTED',
    customer: {
      id: 1,
      firstName: 'Vasiliy',
      lastName: 'Pupkin',
    },
  },
  {
    created: "2018-02-15T11:44:55.267+0000",
    currency: 'ETH',
    amount: -23.07,
    status: 'REJECTED',
    customer: {
      id: 1,
      firstName: 'Vasiliy',
      lastName: 'Pupkin',
    },
  },
  {
    created: "2018-02-11T11:44:55.267+0000",
    currency: 'ETH',
    amount: -13.07,
    status: 'SUCCESS',
    customer: {
      id: 2,
      firstName: 'Gosha',
      lastName: 'Skovorodkin',
    },
  },
  {
    created: "2018-02-11T05:44:55.267+0000",
    currency: 'BTC',
    amount: -13.07,
    status: 'FAILED',
    customer: {
      id: 3,
      firstName: 'Petr',
      lastName: 'Fortochkin',
    },
  },
  {
    created: "2018-02-03T05:44:55.267+0000",
    currency: 'BTC',
    amount: 1300.07,
    status: 'PROCESSING',
    customer: {
      id: 4,
      firstName: 'Ivan',
      lastName: 'Ivanov',
    },
  },
  {
    created: "2018-03-15T11:44:55.267+0000",
    currency: 'BTC',
    amount: 123.05,
    status: 'REQUESTED',
    customer: {
      id: 1,
      firstName: 'Vasiliy',
      lastName: 'Pupkin',
    },
  },
  {
    created: "2018-02-15T11:44:55.267+0000",
    currency: 'ETH',
    amount: -23.07,
    status: 'REJECTED',
    customer: {
      id: 1,
      firstName: 'Vasiliy',
      lastName: 'Pupkin',
    },
  },
  {
    created: "2018-02-11T11:44:55.267+0000",
    currency: 'ETH',
    amount: -13.07,
    status: 'SUCCESS',
    customer: {
      id: 2,
      firstName: 'Gosha',
      lastName: 'Skovorodkin',
    },
  },
  {
    created: "2018-02-11T05:44:55.267+0000",
    currency: 'BTC',
    amount: -13.07,
    status: 'FAILED',
    customer: {
      id: 3,
      firstName: 'Petr',
      lastName: 'Fortochkin',
    },
  },
  {
    created: "2018-02-03T05:44:55.267+0000",
    currency: 'BTC',
    amount: 1300.07,
    status: 'PROCESSING',
    customer: {
      id: 4,
      firstName: 'Ivan',
      lastName: 'Ivanov',
    },
  },
  {
    created: "2018-03-15T11:44:55.267+0000",
    currency: 'BTC',
    amount: 123.05,
    status: 'REQUESTED',
    customer: {
      id: 1,
      firstName: 'Vasiliy',
      lastName: 'Pupkin',
    },
  },
  {
    created: "2018-02-15T11:44:55.267+0000",
    currency: 'ETH',
    amount: -23.07,
    status: 'REJECTED',
    customer: {
      id: 1,
      firstName: 'Vasiliy',
      lastName: 'Pupkin',
    },
  },
  {
    created: "2018-02-11T11:44:55.267+0000",
    currency: 'ETH',
    amount: -13.07,
    status: 'SUCCESS',
    customer: {
      id: 2,
      firstName: 'Gosha',
      lastName: 'Skovorodkin',
    },
  },
  {
    created: "2018-02-11T05:44:55.267+0000",
    currency: 'BTC',
    amount: -13.07,
    status: 'FAILED',
    customer: {
      id: 3,
      firstName: 'Petr',
      lastName: 'Fortochkin',
    },
  },
  {
    created: "2018-02-03T05:44:55.267+0000",
    currency: 'BTC',
    amount: 1300.07,
    status: 'PROCESSING',
    customer: {
      id: 4,
      firstName: 'Ivan',
      lastName: 'Ivanov',
    },
  },
  {
    created: "2018-03-15T11:44:55.267+0000",
    currency: 'BTC',
    amount: 123.05,
    status: 'REQUESTED',
    customer: {
      id: 1,
      firstName: 'Vasiliy',
      lastName: 'Pupkin',
    },
  },
  {
    created: "2018-02-15T11:44:55.267+0000",
    currency: 'ETH',
    amount: -23.07,
    status: 'REJECTED',
    customer: {
      id: 1,
      firstName: 'Vasiliy',
      lastName: 'Pupkin',
    },
  },
  {
    created: "2018-02-11T11:44:55.267+0000",
    currency: 'ETH',
    amount: -13.07,
    status: 'SUCCESS',
    customer: {
      id: 2,
      firstName: 'Gosha',
      lastName: 'Skovorodkin',
    },
  },
  {
    created: "2018-02-11T05:44:55.267+0000",
    currency: 'BTC',
    amount: -13.07,
    status: 'FAILED',
    customer: {
      id: 3,
      firstName: 'Petr',
      lastName: 'Fortochkin',
    },
  },
  {
    created: "2018-02-03T05:44:55.267+0000",
    currency: 'BTC',
    amount: 1300.07,
    status: 'PROCESSING',
    customer: {
      id: 4,
      firstName: 'Ivan',
      lastName: 'Ivanov',
    },
  },
  {
    created: "2018-03-15T11:44:55.267+0000",
    currency: 'BTC',
    amount: 123.05,
    status: 'REQUESTED',
    customer: {
      id: 1,
      firstName: 'Vasiliy',
      lastName: 'Pupkin',
    },
  },
  {
    created: "2018-02-15T11:44:55.267+0000",
    currency: 'ETH',
    amount: -23.07,
    status: 'REJECTED',
    customer: {
      id: 1,
      firstName: 'Vasiliy',
      lastName: 'Pupkin',
    },
  },
  {
    created: "2018-02-11T11:44:55.267+0000",
    currency: 'ETH',
    amount: -13.07,
    status: 'SUCCESS',
    customer: {
      id: 2,
      firstName: 'Gosha',
      lastName: 'Skovorodkin',
    },
  },
  {
    created: "2018-02-11T05:44:55.267+0000",
    currency: 'BTC',
    amount: -13.07,
    status: 'FAILED',
    customer: {
      id: 3,
      firstName: 'Petr',
      lastName: 'Fortochkin',
    },
  },
  {
    created: "2018-02-03T05:44:55.267+0000",
    currency: 'BTC',
    amount: 1300.07,
    status: 'PROCESSING',
    customer: {
      id: 4,
      firstName: 'Ivan',
      lastName: 'Ivanov',
    },
  },
]

const customers = [
  {
    id: 1,
    firstName: 'Vasiliy',
    lastName: 'Pupkin',
    phoneNumber: '+79981234343',
    validationStatus: 'UNCONFIRMED',
    balances: [
      {
        currency: 'BTH',
        amount: 0.00000324,
      },
      {
        currency: 'ETH',
        amount: 0.0005422,
      },
      {
        currency: 'USD',
        amount: 1288.03,
      },
      {
        currency: 'EUR',
        amount: 8234.22,
      },
    ],
  },
  {
    id: 2,
    firstName: 'Gosha',
    lastName: 'Skovorodkin',
    phoneNumber: '+79981234343',
    validationStatus: 'VALIDATED',
    balances: [
      {
        currency: 'BTH',
        amount: 0.00000324,
      },
      {
        currency: 'ETH',
        amount: 0.0005422,
      },
      {
        currency: 'USD',
        amount: 1288.03,
      },
      {
        currency: 'EUR',
        amount: 8234.22,
      },
    ],
  },
  {
    id: 3,
    firstName: 'Petr',
    lastName: 'Fortochkin',
    phoneNumber: '+79981234343',
    validationStatus: 'SUBMITTED',
    balances: [
      {
        currency: 'BTH',
        amount: 0.00000324,
      },
      {
        currency: 'ETH',
        amount: 0.0005422,
      },
      {
        currency: 'USD',
        amount: 1288.03,
      },
      {
        currency: 'EUR',
        amount: 8234.22,
      },
    ],
  },
  {
    id: 4,
    firstName: 'Ivan',
    lastName: 'Ivanov',
    phoneNumber: '+79981234343',
    validationStatus: 'SUBMITTED',
    balances: [
      {
        currency: 'BTH',
        amount: 0.00000324,
      },
      {
        currency: 'ETH',
        amount: 0.0005422,
      },
      {
        currency: 'USD',
        amount: 1288.03,
      },
      {
        currency: 'EUR',
        amount: 8234.22,
      },
    ],
  },
  {
    id: 5,
    firstName: 'Petr',
    lastName: 'Petrov',
    phoneNumber: '+79981234343',
    validationStatus: 'SUBMITTED',
    balances: [
      {
        currency: 'BTH',
        amount: 0.00000324,
      },
      {
        currency: 'ETH',
        amount: 0.0005422,
      },
      {
        currency: 'USD',
        amount: 1288.03,
      },
      {
        currency: 'EUR',
        amount: 8234.22,
      },
    ],
  },
  {
    id: 6,
    firstName: 'John',
    lastName: 'Smith',
    phoneNumber: '+79981234343',
    validationStatus: 'SUBMITTED',
    balances: [
      {
        currency: 'BTH',
        amount: 0.00000324,
      },
      {
        currency: 'ETH',
        amount: 0.0005422,
      },
      {
        currency: 'USD',
        amount: 1288.03,
      },
      {
        currency: 'EUR',
        amount: 8234.22,
      },
    ],
  },
  {
    id: 7,
    firstName: 'Garry',
    lastName: 'Bloom',
    phoneNumber: '+79981234343',
    validationStatus: 'SUBMITTED',
    balances: [
      {
        currency: 'BTH',
        amount: 0.00000324,
      },
      {
        currency: 'ETH',
        amount: 0.0005422,
      },
      {
        currency: 'USD',
        amount: 1288.03,
      },
      {
        currency: 'EUR',
        amount: 8234.22,
      },
    ],
  },
  {
    "id": 10,
    "balance": "$3,948.76",
    "firstName": "Jo",
    "lastName": "Hyde",
    "email": "jo.hyde@undefined.biz",
    "phoneNumber": "+1 (973) 551-2520",
    "address": "317 Conduit Boulevard, Glenshaw, American Samoa, 6763",
    "registered": "Saturday, October 28, 2017 6:25 AM",
    validationStatus: 'VALIDATED',
    balances: [
      {
        currency: 'BTH',
        amount: 0.00000324,
      },
      {
        currency: 'ETH',
        amount: 0.0005422,
      },
      {
        currency: 'USD',
        amount: 1288.03,
      },
      {
        currency: 'EUR',
        amount: 8234.22,
      },
    ],
  },
  {
    "id": 11,
    "balance": "$1,233.92",
    "firstName": "Sawyer",
    "lastName": "Holman",
    "email": "sawyer.holman@undefined.name",
    "phoneNumber": "+1 (999) 484-2446",
    "address": "877 Noble Street, Villarreal, New York, 4619",
    "registered": "Thursday, April 30, 2015 12:53 AM",
    validationStatus: 'VALIDATED',
    balances: [
      {
        currency: 'BTH',
        amount: 0.00000324,
      },
      {
        currency: 'ETH',
        amount: 0.0005422,
      },
      {
        currency: 'USD',
        amount: 1288.03,
      },
      {
        currency: 'EUR',
        amount: 8234.22,
      },
    ],
  },
  {
    "id": 12,
    "balance": "$2,285.98",
    "firstName": "Wall",
    "lastName": "Good",
    "email": "wall.good@undefined.info",
    "phoneNumber": "+1 (822) 489-2921",
    "address": "534 Elliott Place, Hemlock, Alaska, 8063",
    "registered": "Saturday, July 8, 2017 5:46 AM",
    validationStatus: 'VALIDATED',
    balances: [
      {
        currency: 'BTH',
        amount: 0.00000324,
      },
      {
        currency: 'ETH',
        amount: 0.0005422,
      },
      {
        currency: 'USD',
        amount: 1288.03,
      },
      {
        currency: 'EUR',
        amount: 8234.22,
      },
    ],
  },
  {
    "id": 13,
    "balance": "$2,547.64",
    "firstName": "Underwood",
    "lastName": "Gardner",
    "email": "underwood.gardner@undefined.com",
    "phoneNumber": "+1 (941) 560-3148",
    "address": "356 Provost Street, Rivereno, Guam, 265",
    "registered": "Tuesday, March 10, 2015 10:34 PM",
    validationStatus: 'VALIDATED',
    balances: [
      {
        currency: 'BTH',
        amount: 0.00000324,
      },
      {
        currency: 'ETH',
        amount: 0.0005422,
      },
      {
        currency: 'USD',
        amount: 1288.03,
      },
      {
        currency: 'EUR',
        amount: 8234.22,
      },
    ],
  },
  {
    "id": 14,
    "balance": "$2,191.91",
    "firstName": "Penny",
    "lastName": "Davidson",
    "email": "penny.davidson@undefined.ca",
    "phoneNumber": "+1 (856) 536-2656",
    "address": "769 Norfolk Street, Churchill, Palau, 9538",
    "registered": "Sunday, July 5, 2015 6:52 PM",
    validationStatus: 'VALIDATED',
    balances: [
      {
        currency: 'BTH',
        amount: 0.00000324,
      },
      {
        currency: 'ETH',
        amount: 0.0005422,
      },
      {
        currency: 'USD',
        amount: 1288.03,
      },
      {
        currency: 'EUR',
        amount: 8234.22,
      },
    ],
  },
  {
    "id": 15,
    "balance": "$1,558.58",
    "firstName": "Dianne",
    "lastName": "Morse",
    "email": "dianne.morse@undefined.biz",
    "phoneNumber": "+1 (984) 440-3926",
    "address": "680 Lawn Court, Enoree, Kansas, 7121",
    "registered": "Wednesday, April 13, 2016 7:13 AM",
    validationStatus: 'VALIDATED',
    balances: [
      {
        currency: 'BTH',
        amount: 0.00000324,
      },
      {
        currency: 'ETH',
        amount: 0.0005422,
      },
      {
        currency: 'USD',
        amount: 1288.03,
      },
      {
        currency: 'EUR',
        amount: 8234.22,
      },
    ],
  },
  {
    "id": 16,
    "balance": "$2,337.97",
    "firstName": "Patterson",
    "lastName": "Alvarado",
    "email": "patterson.alvarado@undefined.us",
    "phoneNumber": "+1 (923) 492-3271",
    "address": "551 Village Court, Wattsville, Mississippi, 6335",
    "registered": "Saturday, April 7, 2018 8:48 AM",
    validationStatus: 'VALIDATED',
    balances: [
      {
        currency: 'BTH',
        amount: 0.00000324,
      },
      {
        currency: 'ETH',
        amount: 0.0005422,
      },
      {
        currency: 'USD',
        amount: 1288.03,
      },
      {
        currency: 'EUR',
        amount: 8234.22,
      },
    ],
  },
  {
    "id": 17,
    "balance": "$3,793.06",
    "firstName": "Stanley",
    "lastName": "Rice",
    "email": "stanley.rice@undefined.me",
    "phoneNumber": "+1 (911) 593-2522",
    "address": "803 Thames Street, Slovan, Utah, 963",
    "registered": "Friday, November 13, 2015 8:22 PM",
    validationStatus: 'VALIDATED',
    balances: [
      {
        currency: 'BTH',
        amount: 0.00000324,
      },
      {
        currency: 'ETH',
        amount: 0.0005422,
      },
      {
        currency: 'USD',
        amount: 1288.03,
      },
      {
        currency: 'EUR',
        amount: 8234.22,
      },
    ],
  },
  {
    "id": 18,
    "balance": "$1,671.45",
    "firstName": "Coleen",
    "lastName": "Wilder",
    "email": "coleen.wilder@undefined.org",
    "phoneNumber": "+1 (943) 535-3590",
    "address": "923 Maple Street, Homeworth, Kentucky, 4260",
    "registered": "Sunday, February 25, 2018 1:03 PM",
    validationStatus: 'VALIDATED',
    balances: [
      {
        currency: 'BTH',
        amount: 0.00000324,
      },
      {
        currency: 'ETH',
        amount: 0.0005422,
      },
      {
        currency: 'USD',
        amount: 1288.03,
      },
      {
        currency: 'EUR',
        amount: 8234.22,
      },
    ],
  },
  {
    "id": 19,
    "balance": "$1,256.62",
    "firstName": "Gross",
    "lastName": "Torres",
    "email": "gross.torres@undefined.io",
    "phoneNumber": "+1 (856) 482-3972",
    "address": "467 Hegeman Avenue, Lafferty, Alabama, 2573",
    "registered": "Saturday, August 18, 2018 9:20 PM",
    validationStatus: 'VALIDATED',
    balances: [
      {
        currency: 'BTH',
        amount: 0.00000324,
      },
      {
        currency: 'ETH',
        amount: 0.0005422,
      },
      {
        currency: 'USD',
        amount: 1288.03,
      },
      {
        currency: 'EUR',
        amount: 8234.22,
      },
    ],
  },
  {
    "id": 20,
    "balance": "$2,363.21",
    "firstName": "Naomi",
    "lastName": "Fuentes",
    "email": "naomi.fuentes@undefined.net",
    "phoneNumber": "+1 (875) 478-3701",
    "address": "375 Autumn Avenue, Caln, Marshall Islands, 1120",
    "registered": "Saturday, August 6, 2016 5:58 AM",
    validationStatus: 'VALIDATED',
    balances: [
      {
        currency: 'BTH',
        amount: 0.00000324,
      },
      {
        currency: 'ETH',
        amount: 0.0005422,
      },
      {
        currency: 'USD',
        amount: 1288.03,
      },
      {
        currency: 'EUR',
        amount: 8234.22,
      },
    ],
  },
  {
    "id": 21,
    "balance": "$3,941.71",
    "firstName": "Dale",
    "lastName": "Shaffer",
    "email": "dale.shaffer@undefined.tv",
    "phoneNumber": "+1 (996) 546-3395",
    "address": "239 Bay Street, Murillo, Ohio, 5744",
    "registered": "Thursday, December 17, 2015 6:37 AM",
    validationStatus: 'VALIDATED',
    balances: [
      {
        currency: 'BTH',
        amount: 0.00000324,
      },
      {
        currency: 'ETH',
        amount: 0.0005422,
      },
      {
        currency: 'USD',
        amount: 1288.03,
      },
      {
        currency: 'EUR',
        amount: 8234.22,
      },
    ],
  },
  {
    "id": 22,
    "balance": "$1,575.46",
    "firstName": "Elba",
    "lastName": "Lindsey",
    "email": "elba.lindsey@undefined.biz",
    "phoneNumber": "+1 (839) 509-3488",
    "address": "941 Fiske Place, Downsville, Nevada, 3384",
    "registered": "Monday, June 12, 2017 11:50 PM",
    validationStatus: 'VALIDATED',
    balances: [
      {
        currency: 'BTH',
        amount: 0.00000324,
      },
      {
        currency: 'ETH',
        amount: 0.0005422,
      },
      {
        currency: 'USD',
        amount: 1288.03,
      },
      {
        currency: 'EUR',
        amount: 8234.22,
      },
    ],
  },
  {
    "id": 23,
    "balance": "$1,150.84",
    "firstName": "Pena",
    "lastName": "Odom",
    "email": "pena.odom@undefined.name",
    "phoneNumber": "+1 (822) 598-2954",
    "address": "973 Kenmore Court, Coleville, Connecticut, 7045",
    "registered": "Monday, April 11, 2016 5:24 PM",
    validationStatus: 'VALIDATED',
    balances: [
      {
        currency: 'BTH',
        amount: 0.00000324,
      },
      {
        currency: 'ETH',
        amount: 0.0005422,
      },
      {
        currency: 'USD',
        amount: 1288.03,
      },
      {
        currency: 'EUR',
        amount: 8234.22,
      },
    ],
  },
  {
    "id": 24,
    "balance": "$1,672.37",
    "firstName": "Robles",
    "lastName": "Boone",
    "email": "robles.boone@undefined.info",
    "phoneNumber": "+1 (820) 480-2308",
    "address": "345 Seaview Avenue, Frystown, Maryland, 1449",
    "registered": "Wednesday, August 9, 2017 6:22 PM",
    validationStatus: 'VALIDATED',
    balances: [
      {
        currency: 'BTH',
        amount: 0.00000324,
      },
      {
        currency: 'ETH',
        amount: 0.0005422,
      },
      {
        currency: 'USD',
        amount: 1288.03,
      },
      {
        currency: 'EUR',
        amount: 8234.22,
      },
    ],
  },
  {
    "id": 25,
    "balance": "$3,919.47",
    "firstName": "Salas",
    "lastName": "Bradshaw",
    "email": "salas.bradshaw@undefined.com",
    "phoneNumber": "+1 (891) 441-3688",
    "address": "780 Amboy Street, Marbury, Maine, 1007",
    "registered": "Saturday, December 27, 2014 10:36 PM",
    validationStatus: 'VALIDATED',
    balances: [
      {
        currency: 'BTH',
        amount: 0.00000324,
      },
      {
        currency: 'ETH',
        amount: 0.0005422,
      },
      {
        currency: 'USD',
        amount: 1288.03,
      },
      {
        currency: 'EUR',
        amount: 8234.22,
      },
    ],
  },
  {
    "id": 26,
    "balance": "$1,027.96",
    "firstName": "Dena",
    "lastName": "Howe",
    "email": "dena.howe@undefined.ca",
    "phoneNumber": "+1 (830) 474-3162",
    "address": "167 Garnet Street, Guilford, Northern Mariana Islands, 5319",
    "registered": "Saturday, November 29, 2014 9:08 PM",
    validationStatus: 'VALIDATED',
    balances: [
      {
        currency: 'BTH',
        amount: 0.00000324,
      },
      {
        currency: 'ETH',
        amount: 0.0005422,
      },
      {
        currency: 'USD',
        amount: 1288.03,
      },
      {
        currency: 'EUR',
        amount: 8234.22,
      },
    ],
  },
  {
    "id": 27,
    "balance": "$1,406.98",
    "firstName": "Finch",
    "lastName": "Bates",
    "email": "finch.bates@undefined.biz",
    "phoneNumber": "+1 (945) 464-3215",
    "address": "409 Billings Place, Dupuyer, Montana, 3953",
    "registered": "Saturday, September 17, 2016 4:51 AM",
    validationStatus: 'VALIDATED',
    balances: [
      {
        currency: 'BTH',
        amount: 0.00000324,
      },
      {
        currency: 'ETH',
        amount: 0.0005422,
      },
      {
        currency: 'USD',
        amount: 1288.03,
      },
      {
        currency: 'EUR',
        amount: 8234.22,
      },
    ],
  },
  {
    "id": 28,
    "balance": "$3,533.32",
    "firstName": "Tanisha",
    "lastName": "Richmond",
    "email": "tanisha.richmond@undefined.us",
    "phoneNumber": "+1 (979) 453-2923",
    "address": "451 Keen Court, Nadine, Michigan, 1598",
    "registered": "Wednesday, April 20, 2016 3:23 PM",
    validationStatus: 'VALIDATED',
    balances: [
      {
        currency: 'BTH',
        amount: 0.00000324,
      },
      {
        currency: 'ETH',
        amount: 0.0005422,
      },
      {
        currency: 'USD',
        amount: 1288.03,
      },
      {
        currency: 'EUR',
        amount: 8234.22,
      },
    ],
  },
  {
    "id": 29,
    "balance": "$2,165.92",
    "firstName": "Erica",
    "lastName": "Espinoza",
    "email": "erica.espinoza@undefined.me",
    "phoneNumber": "+1 (810) 507-2142",
    "address": "832 Box Street, Cawood, Arizona, 2780",
    "registered": "Saturday, September 16, 2017 10:06 PM",
    validationStatus: 'VALIDATED',
    balances: [
      {
        currency: 'BTH',
        amount: 0.00000324,
      },
      {
        currency: 'ETH',
        amount: 0.0005422,
      },
      {
        currency: 'USD',
        amount: 1288.03,
      },
      {
        currency: 'EUR',
        amount: 8234.22,
      },
    ],
  }
]
