import {combineReducers} from 'redux'
import {routerReducer} from 'react-router-redux'
import {reducer as formReducer} from 'redux-form'
import auth, {LOGOUT_SUCCESS} from './auth'
import alert from './alert'
import info from './info'
import busy from './busy'
import ui from './ui'
import confirmation from './confirmation'
import transfer from './transfer'
import customer from './customer'
import currency from './currency'
import partnerWallet from './partnerWallet'
import locale from './locale'

const appReducer = combineReducers({
  routing: routerReducer,
  form: formReducer.plugin({
  }),
  alert,
  info,
  auth,
  busy,
  ui,
  confirmation,
  transfer,
  customer,
  currency,
  partnerWallet,
  locale,
})

export default (state, action) => {
  if (action.type === LOGOUT_SUCCESS) {
    const {ui} = state
    state = {ui}
  }

  return appReducer(state, action)
}
