import React, {Fragment} from 'react'
import {Trans, translate} from 'react-i18next'
import {compose} from 'redux'
import {withStyles} from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Collapse from '@material-ui/core/Collapse'
import {LinkButton} from 'components/Form'

const styles = () => ({
  expandable: {
    overflow: 'visible',
  },
})

class AdditionalInfoPanel extends React.Component {
  state = {open: false}

  handleClick = () => {
    this.setState(state => ({open: !state.open}))
  };

  render() {
    const {additional, classes} = this.props
    return <Fragment>
      <LinkButton onClick={this.handleClick}>
        {this.state.open ? <Trans>Hide additional info</Trans> : <Trans>Show additional info</Trans>}
      </LinkButton>
      <Collapse in={this.state.open} className={classes.expandable} unmountOnExit>
        {additional.map((info, index) => <Typography key={index}>{info}</Typography>)}
      </Collapse>
    </Fragment>
  }
}

export default compose(
  translate(),
  withStyles(styles),
)(AdditionalInfoPanel)
