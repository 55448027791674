import React from 'react'
import ReactDOM from 'react-dom'
import './style/base.scss'
import {history} from 'common/utils/history'
import store from './store'
import Root from './Root'
import './fontawesomeLib'
import 'typeface-roboto'

ReactDOM.render(
  <Root store={store} history={history} />,
  document.getElementById('root')
)
