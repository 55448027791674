import React from 'react'
import {translate} from 'react-i18next'
import MuiThemeProvider from '@material-ui/core/styles/MuiThemeProvider'
import getTheme from 'style/theme'

const ThemeProvider = ({i18n, children}) => {
  const currentLang = i18n.languages[0]
  const theme = getTheme(currentLang)
  return (
    <MuiThemeProvider theme={theme}>
      {children}
    </MuiThemeProvider>
  )
}

export default translate()(ThemeProvider)
