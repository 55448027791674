import React from 'react'
import {compose} from 'redux'
import {withStyles} from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import LanguageSelector from 'Root/LanguageSelector'
import logo from 'style/main_logo.png'

const styles = theme => ({
  logotypedForm: {
    margin: '0 auto',
  },
  logo: {
    marginTop: 100,
    marginBottom: 80,
    textAlign: 'center',
  },
  logoImg: {
    height: 32,
  },
  logoForm: theme.mixins.gutters({
    padding: theme.spacing.unit * 3,
    width: 380,
    margin: 'auto',
    marginBottom: 80,
  }),
})

class LogotypedForm extends React.Component {
  render() {
    const {onSubmit, classes, children} = this.props
    return (
      <div className={classes.logotypedForm}>
        <form onSubmit={onSubmit}>
          <div className={classes.logo}>
            <img className={classes.logoImg} src={logo} alt="" />
          </div>
          <Paper className={classes.logoForm} elevation={4}>
            {children}
          </Paper>
        </form>
        <LanguageSelector hideLabel />
      </div>
    )
  }
}

export default compose(
  withStyles(styles),
)(LogotypedForm)
