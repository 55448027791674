import React from 'react'
import classNames from 'classnames'
import {withStyles} from '@material-ui/core/styles'

const styles = theme => ({
  '@keyframes spin': {
    '0%': {transform: 'rotate(0deg)'},
    '100%': {transform: 'rotate(360deg)'},
  },
  loader: {
    border: `4px solid ${theme.palette.secondary.main}`,
    borderTopColor: 'transparent',
    borderRadius: '50%',
    width: 50,
    height: 50,
    animation: 'spin 1s linear infinite',
    margin: '200px auto',
    '&$loader': {
      flexShrink: 0,
      flexGrow: 0,
    },
  },
  small: {
    borderWidth: 1,
    width: 16,
    height: 16,
    margin: 'auto',
  },
  overlay: {
    position: 'absolute',
    left: 0,
    top: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(255, 255, 255, 0.75)',
    zIndex: theme.zIndex.drawer - 1,
  },
})

class Loader extends React.PureComponent {
  static defaultProps = {
    delayed: false,
  }

  state = {
    showLoader: false,
  }

  render() {
    const {overlay, small, className, classes, delayed} = this.props
    const {showLoader} = this.state
    const loader = <div className={classNames(classes.loader, {[classes.small]: small}, className)} />
    if (overlay) {
      return <div className={classes.overlay}>{ (!delayed || showLoader) && loader }</div>
    }
    return loader
  }

  componentDidMount() {
    if (this.props.delayed) {
      this.timer = setTimeout(() => this.setState({showLoader: true}), 500)
    }
  }

  componentWillUnmount() {
    clearTimeout(this.timer)
  }
}

export default withStyles(styles, {withTheme: true})(Loader)
