import React from 'react'
import {PropTypes} from 'prop-types'
import {translate, Trans} from 'react-i18next'
import Button from '@material-ui/core/Button'
import {withStyles} from '@material-ui/core/styles'
import {compose} from 'redux'

const styles = () => ({
  button: {
    color: 'white',
  },
})

const LanguageSelector = ({i18n, hideLabel, classes}) =>
  <div className="lang-selector">
    {!hideLabel && <Trans>Language:</Trans>}
    <ul>
      {LANGUAGES.map((c) =>
        <li key={c.code}>
          <Button
            className={classes.button}
            size="small"
            onClick={() => i18n.changeLanguage(c.code)}
          >
            {c.label}
          </Button>
        </li>)
      }
    </ul>
  </div>

LanguageSelector.propTypes = {
  i18n: PropTypes.object.isRequired,
}

export default compose(
  translate(),
  withStyles(styles, {withTheme: true}),
)(LanguageSelector)

export const LANGUAGES = [
  {
    code: 'en',
    label: <Trans>ENGLISH_LANGUAGE_LABEL</Trans>,
  },
  {
    code: 'ru',
    label: <Trans>RUSSIAN_LANGUAGE_LABEL</Trans>,
  },
]
