const INFO_SHOW = 'Info/INFO_SHOW'
const INFO_CLEAR = 'Info/INFO_CLEAR'

export default (state = {message: null, key: null}, action) => {
  switch (action.type) {
    case INFO_SHOW:
      return {
        message: action.message,
        key: new Date().getTime(),
      }
    case INFO_CLEAR:
      return {
        ...state,
        message: null,
      }
    default:
      return state
  }
}

export const infoActions = {
  showInfo,
  hideInfo,
}

export function showInfo(message) {
  return {type: INFO_SHOW, message}
}

export function hideInfo() {
  return {type: INFO_CLEAR}
}
