import React from 'react'
import {Field, reduxForm} from 'redux-form'
import {translate, Trans} from 'react-i18next'
import {compose} from 'redux'
import * as Validators from 'common/Validators'
import LogotypedForm from 'components/LogotypedForm'
import {FormInput} from 'components/Form'
import {withStyles} from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import {ButtonWithLoader} from 'components/ButtonWithLoader'
import {Link} from 'react-router-dom'

const styles = theme => ({
  password: {
    marginBottom: theme.spacing.unit * 6,
  },
})

let LoginForm = ({handleSubmit, submitting, classes,
}) =>
  <LogotypedForm onSubmit={handleSubmit}>
    <Field fullWidth name="username" component={FormInput} label={<Trans>Username</Trans>}
      autoComplete="username"
      InputLabelProps={{shrink: true}}
      validate={Validators.required}
      autoFocus
    />
    <Field fullWidth name="password" type="password" component={FormInput} label={<Trans>Password</Trans>}
      autoComplete="current-password"
      className={classes.password}
      InputLabelProps={{shrink: true}}
      validate={Validators.required}
    />
    <Grid container justify="space-between">
      <Button component={Link} to="/forgot-password" color="primary">
        <Trans>Forgot password</Trans>
      </Button>

      {/* Sign In button is not disabled when the form is invalid intentionally
          because any error is shown as *username* error and e.g. after fixing
          wrong password the button will still be disabled
       */}
      <ButtonWithLoader type="submit" variant="contained" color="primary"
        loading={submitting}
      >
        <Trans>Sign In</Trans>
      </ButtonWithLoader>
    </Grid>
  </LogotypedForm>

export default compose(
  translate(),
  reduxForm({
    form: 'loginForm',  // a unique identifier for this form
  }),
  withStyles(styles),
)(LoginForm)
