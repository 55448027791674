import {library} from '@fortawesome/fontawesome-svg-core'
import {faBell} from '@fortawesome/free-solid-svg-icons'
import {faPlus} from '@fortawesome/free-solid-svg-icons'
import {faPlusCircle} from '@fortawesome/free-solid-svg-icons'
import {faTrashAlt} from '@fortawesome/free-regular-svg-icons'
import {faChevronLeft} from '@fortawesome/free-solid-svg-icons'
import {faPencilAlt} from '@fortawesome/free-solid-svg-icons'
import {faCircleNotch} from '@fortawesome/free-solid-svg-icons'
import {faPlay} from '@fortawesome/free-solid-svg-icons'
import {faTimesCircle} from '@fortawesome/free-solid-svg-icons'
import {faDatabase} from '@fortawesome/free-solid-svg-icons'
import {faBox} from '@fortawesome/free-solid-svg-icons'
import {faBoxes} from '@fortawesome/free-solid-svg-icons'
import {faTasks} from '@fortawesome/free-solid-svg-icons'
import {faSearch} from '@fortawesome/free-solid-svg-icons'
import {faEllipsisV} from '@fortawesome/free-solid-svg-icons'
import {faStar} from '@fortawesome/free-solid-svg-icons'
import {faBuilding} from '@fortawesome/free-solid-svg-icons'
import {faUser} from '@fortawesome/free-solid-svg-icons'
import {faUsers} from '@fortawesome/free-solid-svg-icons'
import {faHome} from '@fortawesome/free-solid-svg-icons'
import {faPlusSquare} from '@fortawesome/free-solid-svg-icons'
import {faBook} from '@fortawesome/free-solid-svg-icons'
import {faGlobeAsia} from '@fortawesome/free-solid-svg-icons'
import {faAmbulance} from '@fortawesome/free-solid-svg-icons'
import {faFileAlt} from '@fortawesome/free-solid-svg-icons'
import {faGripVertical} from '@fortawesome/free-solid-svg-icons'
import {faCog} from '@fortawesome/free-solid-svg-icons'
import {faFolder} from '@fortawesome/free-solid-svg-icons'
import {faArrowCircleLeft} from '@fortawesome/free-solid-svg-icons'
import {faArrowUp} from '@fortawesome/free-solid-svg-icons'
import {faArrowDown} from '@fortawesome/free-solid-svg-icons'
import {faChevronRight} from '@fortawesome/free-solid-svg-icons'
import {faCube} from '@fortawesome/free-solid-svg-icons'
import {faServer} from '@fortawesome/free-solid-svg-icons'
import {faClipboardList} from '@fortawesome/free-solid-svg-icons'

library.add(
  faBell,
  faPlus,
  faPlusCircle,
  faPlusSquare,
  faTrashAlt,
  faChevronLeft,
  faPencilAlt,
  faCircleNotch,
  faPlay,
  faTimesCircle,
  faDatabase,
  faBox,
  faBoxes,
  faTasks,
  faSearch,
  faEllipsisV,
  faStar,
  faBuilding,
  faUser,
  faUsers,
  faHome,
  faBook,
  faGlobeAsia,
  faAmbulance,
  faFileAlt,
  faGripVertical,
  faCog,
  faFolder,
  faArrowCircleLeft,
  faArrowUp,
  faArrowDown,
  faChevronRight,
  faCube,
  faServer,
  faClipboardList,
)
