const SET_DRAWER_STATE = 'UI/SET_DRAWER_STATE'
const SET_CONTENT_WIDTH = 'UI/SET_CONTENT_WIDTH'

const initialState = {
  isDrawerOpen: true,
  contentWidth: 0,
}

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case SET_DRAWER_STATE:
      return {
        ...state,
        isDrawerOpen: action.payload.isDrawerOpen,
      }
    case SET_CONTENT_WIDTH:
      return {
        ...state,
        contentWidth: action.payload.width,
      }
    default:
      return state
  }
}

export function closeDrawer() {
  return {
    type: SET_DRAWER_STATE,
    payload: {isDrawerOpen: false},
  }
}

export function openDrawer() {
  return {
    type: SET_DRAWER_STATE,
    payload: {isDrawerOpen: true},
  }
}

export function setContentWidth(width) {
  return {
    type: SET_CONTENT_WIDTH,
    payload: {width},
  }
}
