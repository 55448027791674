import React, {Component} from 'react'
import Loader from 'components/Loader'
import {withStyles} from '@material-ui/core/styles'
import {connect} from 'react-redux'
import {Trans, translate} from 'react-i18next'
import {bindActions} from 'common/utils'
import {compose} from 'redux'
import {handleAuthCallback} from 'ducks/auth'

const styles = theme => ({
  root: {
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
  },
  content: {
    textAlign: 'center',
    marginTop: '40px',
  }
})

class Callback extends Component {
  componentDidMount() {
    const params = new URLSearchParams(this.props.location.search);
    if (params.get('code') && params.get('state')) {
      this.props.actions.handleAuthCallback()
    }
  }

  render() {
    const {classes, authError} = this.props

    return (
      <div className={classes.root}>
        <h1 className={classes.content}>
          {!authError && <Loader overlay />}
          {authError && (
            <div><Trans>Something went wrong. Please try again</Trans></div>
          )}
        </h1>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    authError: state.auth.authError,
  }
}

export default compose(
  connect(mapStateToProps, bindActions({handleAuthCallback})),
  translate(),
  withStyles(styles, {withTheme: true}),
)(Callback)
