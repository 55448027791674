import {history} from 'common/utils/history'
import {configureStore} from './configureStore'
import {loadState, saveState} from './localStorage'
import throttle from 'lodash/throttle'

const persistedState = loadState()
const store = configureStore(persistedState, history)

store.subscribe(throttle(() => {
  const {auth, ui} = store.getState()
  saveState({
    auth,
    ui,
  })
}), 1000)

export default store
