import auth from 'ducks/auth/AuthService';

export function authHeader() {
  return auth.getAccessToken()
    .then(token => {
      return {'Authorization': 'Bearer ' + token}
    })
    .catch(e => {
      return {}
    })
}
