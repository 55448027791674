export const formatNumber = (number, options = {}) => {
  const { precision = 2 } = options
  const lang = sessionStorage.getItem('lang') || 'en'

  const formatter = new Intl.NumberFormat(lang, {
    minimumFractionDigits: precision,
    maximumFractionDigits: precision,
  });

  return formatter.format(number)
};

export const fractionDigits = (currencies, currency) => {
  const cur_info = currencies && currencies.find(c => c.id === currency)
  return (cur_info && cur_info.roundingPrecision) || 2
}

const formatCurrency = (value, currency, currencies) => {
  const precision = fractionDigits(currencies, currency)
  return formatNumber(value, { precision })
}

export default formatCurrency

