import * as currencyApi from './CurrencyService'
import {handleError} from 'common/utils'

const LOAD = 'currency/list/LOAD'
const LOAD_SUCCESS = 'currency/list/LOAD_SUCCESS'
const LOAD_FAIL = 'currency/list/LOAD_FAIL'

const initialState = {
  currencies: null,
}

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case LOAD_SUCCESS:
      return {
        ...state,
        currencies: action.payload.currencies.currencyList
      }

    default:
      return state
  }
}

export const loadCurrencies = ({blockUi} = {blockUi: false}) =>
  dispatch => {
    const meta = {isBlocking: blockUi}
    dispatch({
      type: LOAD,
      meta,
    })
    return currencyApi.getCurrencies()
      .then(currencies => {
        dispatch({
          type: LOAD_SUCCESS,
          payload: {currencies},
          meta,
        })
      })
      .catch(error => {
        dispatch({
          type: LOAD_FAIL,
          meta,
        })
        throw error
      })
      .catch(handleError())
  }
