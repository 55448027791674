import React, {Fragment} from 'react'
import {withStyles} from '@material-ui/core/styles'
import get from 'lodash/get'
import classNames from 'classnames'
import InputLabel from '@material-ui/core/InputLabel'
import {LeftLabelContext} from './FormSection'

const FieldLeftLabel = withStyles(theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
  },
  label: {
    color: theme.palette.text.primary,
    fontSize: 14,
    fontWeight: 500,
  },
  required: {
    color: theme.palette.error.main,
  },
}), {withTheme: true})(
  ({label, meta = {}, labelClassName, classes, children, className, component}) => {
    const {error} = meta
    const isRequired = error && get(error, 'props.children') === 'Required'
    if (isRequired) {
      label = <Fragment>{label} <span className={classes.required}>*</span></Fragment>
    }
    return (
      <InputLabel className={classNames(classes.root, className)} component={component}>
        <LeftLabelContext.Consumer>
          { ctx => 
            <span className={classNames(classes.label, labelClassName || ctx.labelClassName)}>{label}</span>
          }
        </LeftLabelContext.Consumer>
        {children}
      </InputLabel>
    )
  }
)
export default FieldLeftLabel
