import React from 'react'
import {Trans} from 'react-i18next'

const ERROR_MESSAGES = {
  TEST_ERROR: <Trans>Test Error</Trans>,
}

export default function getMessageByErrorCode(errorCode) {
  const msg = ERROR_MESSAGES[errorCode]
  return msg == null ? errorCode : msg
}
